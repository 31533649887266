.user-view {
  .back-arrow {
    .goto {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: inherit;
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-decoration: none;
      .arrow-main {
        color: #4318ff;
        width: 24px;
        height: 24px;
      }
    }
  }
  .add-text {
    margin-top: 70px;
    color: #212121;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
  }
  .user-data-container {
    margin-top: 50px;
    .userRole {
      max-width: 280px;
      font-family: "Poppins";
    }
    .user-name {
      margin-top: 44px;
      display: flex;
      gap: 70px;
      .input-container {
        min-width: 248px;
      }
    }
    .user-contact {
      margin-top: 44px;
      display: flex;
      gap: 70px;
      .input-container {
        min-width: 248px;
      }
      .contact-number {
        display: flex;
        gap: 20px;
        width: fit-content;
        .drop-down-container {
          width: 95px;
          & > div {
            margin-top: 28px;
          }
        }
        .input-container {
          max-width: fit-content;
          min-width: fit-content;
          .label {
            margin-left: -115px;
          }
        }
      }
      .time-zone {
        .drop-down-container {
          min-width: 248px;
          .select-value {
            margin-top: 4px;
          }
        }
      }
    }
    .location-main {
      margin-top: 50px;
      .title {
        display: flex;
        align-items: center;
        gap: 20px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        cursor: pointer;
        width: fit-content;
      }
      .location-data {
        border-radius: 8px;
        border: 2px solid rgba(79, 79, 79, 0.16);
        background: #f8f9fb;
        padding: 20px;
        .titles {
          font-family: "Poppins";
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > div {
            flex: 1; /* Each div takes equal width */
            margin-right: 10px; /* Adjust spacing between divs as needed */
            // margin-left: 10px;
          }
          .delete-icon {
            width: fit-content;
            cursor: pointer;
            // border: 1px solid;
            color: black;
            max-width: 25px;
          }
          & > div:last-child {
            width: 50px;
            flex: 0;
          }
        }
        & > div:nth-child(1) {
          & > div:nth-child(2) {
            margin-left: -20px;
          }
          & > div:nth-child(3) {
            margin-left: -30px;
          }
        }
        .add-more {
          width: fit-content;
          margin-top: 22px;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 2px;
          color: #4318ff;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
      }
    }
    .submit-button {
      width: fit-content;
      margin: 50px 0;
      & > button {
        border-radius: 6px;
        background: #4318ff;
        padding: 12px 30px;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}

.input-container {
  .label {
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .input-content {
    margin-top: 8px;
    width: 100%;
    // border: 1px solid blue;
    & > input {
      // width: calc(100% - 34px);
      width: 100%;
      border-radius: 4px;
      border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
      padding: 13px 16px;
      color: #111;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      outline: none;
      // border: 1px solid red;
    }
    & > input::placeholder {
      font-weight: 400;
    }
  }
}

.success-message {
  border-radius: 8px;
  background: #fff;
  width: 410px;
  height: 232px;
  flex-shrink: 0;
  position: relative;
  .cross {
    position: absolute;
    right: 20px;
    top: -15px;
    cursor: pointer;
    // display: none;
  }
  .user {
    text-align: center;
    margin-top: 35px;
  }
  .desc {
    width: 263px;
    color: #4f4f4f;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    margin: 0 auto;
    margin-top: 20px;
  }
  .okay {
    text-align: center;
    margin-top: 40px;
    & > button {
      border-radius: 6px;
      background: #4318ff;
      padding: 10px 30px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
