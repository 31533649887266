.global-loader {
  position: fixed;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: rgba(97, 97, 97, 0.85);
  inset: 0;
  overflow: hidden;
  z-index: 150000;
  display: flex;
  align-items: center;
  justify-content: center;
}
