.view-client {
  .title {
    max-width: 98%;
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    & > div:nth-child(1) {
      color: #212121;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 30.8px */
    }
    & > div:nth-child(2) {
      width: fit-content;

      & > button {
        border-radius: 6px;
        background: #4318ff;
        color: #fff;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 9px 16px;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .searchAndFilter {
    margin-top: 18px;
    display: flex;
    max-width: 98%;
    justify-content: space-between;
    align-items: center;
    .searchMain {
    }
    .filersButton {
      margin-bottom: 16px;
      border-radius: 6px;
      background: #4318ff;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 7px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      gap: 10px;
      max-height: 44px;
    }
  }

  .table-container {
    width: 98%;
    margin-top: 30px;
    overflow-x: auto; // Allows horizontal scrolling
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.1);
    .user-table {
      min-width: 100%; // Minimum width of the table
      border-collapse: collapse; // Removes space between borders
      background: #f3f0ff;

      thead {
        background: #f3f0ff;
        tr {
          // padding: 20px;
          th {
            text-align: left;
            white-space: nowrap; // Keeps the header text on a single line
            color: #616161;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
          }
          th:nth-child(1) {
            padding: 20px;
            padding-right: 0px;
          }
          th:nth-last-child(1) {
            padding: 20px;
            padding-left: 0px;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background-color: #f6f6f6;
          }

          td {
            color: var(--Secondary-Black, #616161);
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            // padding: 20px;
          }
          td:nth-child(1) {
            padding: 20px;
            padding-right: 0px;
          }
          td:nth-last-child(1) {
            padding: 20px;
            padding-left: 0px;
          }
          .active {
            color: green;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            text-decoration-line: underline;
          }
          .inactive {
            color: #999;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            text-decoration-line: underline;
          }
          .details {
            & > button {
              border-radius: 4px;
              border: 1px solid #4318ff;
              color: #4318ff;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              padding: 2px 6px;
              height: fit-content;
              cursor: pointer;
            }
          }
          .edit {
            color: #4318ff;
          }
          .delete {
            color: #e31414;
          }
          .edit,
          .delete {
            & > button {
              border: none;
              outline: none;
              cursor: pointer;
              color: inherit;
              background: inherit;
            }
          }
        }
      }
    }
  }
}

.delete-user {
  border-radius: 8px;
  background: #fff;
  width: 410px;
  height: 232px;
  flex-shrink: 0;
  position: relative;
  .cross {
    position: absolute;
    right: 20px;
    top: -15px;
    cursor: pointer;
    // display: none;
  }
  .user {
    text-align: center;
    margin-top: 42px;
    svg {
      height: 36px;
      width: 36px;
    }
  }
  .desc {
    // width: 263px;
    color: #4f4f4f;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    margin: 0 auto;
    margin-top: 20px;
  }
  .okay {
    margin-top: 40px;
    display: flex;
    gap: 30px;
    justify-content: center;
    & > button {
      border-radius: 6px;
      background: #e31414;
      padding: 10px 30px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    & > button:nth-child(1) {
      background: #fff;
      color: #777;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 6px;
      border: 1px solid #777;
    }
  }
}
