/* General styles for the page */
body {
  font-family: "Poppins";
  /* Use a web-safe font */
  background-color: #fff;
  /* Light grey background for slight contrast */
  color: #333;
  /* Dark grey text for readability */
  margin: 0;
  /* padding: 20px; */
}

.rightSideMain {
  display: flex;
  justify-content: center;
  /* Center the form in the page */
  /* align-items: center; */
  /* Align vertically */
  min-height: 100vh;
  width: fit-content;
  margin: 0 auto;
  /* Full viewport height */
}

.childDiv {
  background: white;
  /* White background for the form area */
  padding: 20px;
  border-radius: 8px;
  /* Rounded corners for modern look */
  /* Subtle shadow for depth */
  width: 100%;
  /* Full width on small screens */
  max-width: 400px;
  /* Maximum width of the form */
}

.title {
  font-size: 24px;
  /* Larger font size for headings */
  margin-bottom: 20px;
  /* Space below the title */
  text-align: center;
  /* Center-align the title */
}

.formWrapper {
  display: flex;
  flex-direction: column;
  /* Stack form elements vertically */
}

.label {
  font-weight: bold;
  /* Make labels bold */
  margin-bottom: 5px;
  /* Small margin below the label */
}

.passwordWrapper,
.confirm-password-wrapper {
  margin-bottom: 20px;
  /* Space between form sections */
}

.content-input {
  margin-bottom: 10px;
  /* Space below each input */
}

.criteria {
  font-size: 14px;
  /* Smaller font size for password criteria */
  color: #666;
  /* Lighter text for less emphasis */
  margin-bottom: 20px;
  /* Space below criteria before next section */
}

.otpError {
  color: #d32f2f;
  /* Red color for errors */
  display: flex;
  align-items: center;
  /* Align icon and text */
  font-size: 14px;
  margin-top: 5px;
  /* Space above error message */
}

.otpError svg {
  margin-right: 5px;
  /* Space between icon and text */
}

.last-button {
  background-color: #007bff;
  /* Bootstrap primary button color */
  color: white;
  /* White text on buttons */
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* Pointer cursor on hover */
  font-size: 16px;
  /* Readable font size */
  text-transform: uppercase;
  /* Uppercase button text for emphasis */
}

.last-button:hover {
  background-color: #0056b3;
  /* Darker on hover for visual feedback */
}

@media (max-width: 768px) {
  .childDiv {
    padding: 10px;
    /* Smaller padding on smaller screens */
  }
}
