.progress-bar-main {
  display: flex;

  .progress-bar {
    width: 141px;
    height: 18px;
    position: relative;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 36px;
    margin-left: 50px;

    .progress-fill {
      height: 100%;
      background-color: green;
      border-radius: 10px 0 0 10px;
    }
  }

  .rating {
    color: #062b6e;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
}
