.add-rubric-main {
  position: relative;
  padding: 40px 28px 20px 30px;
  border-radius: 12px;
  background: #fff;
  width: 714px;
  min-height: 380px;
  font-family: "Poppins";
  box-sizing: border-box;

  .closeBox {
    position: absolute;
    right: 22px;
    top: 24px;
    width: fit-content;
    height: fit-content;

    img {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }
  }

  .title {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    height: 28px;
    display: flex;
  }

  .sectionName {
    height: 48px;
    margin: 24px 0;
    display: flex;
    justify-content: space-between;

    & > input {
      width: 280px;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #cfcdcd;
      background: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding: 12px 16px;
    }

    .add-button {
      display: flex;
      align-items: flex-end;
      & > button {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
  .questions-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 656px;
    border: 1px solid #cfcdcd;
    padding: 24px 18px;

    .question {
      display: flex;
      gap: 30px;

      & > textarea {
        width: 560px;
        border-radius: 4px;
        border: 1px solid #cfcdcd;
        background: #fff;
        resize: none;
        font-size: 16px;
        padding: 12px 16px;
        font-family: Poppins;
        box-sizing: border-box;
      }

      .delete {
        display: flex;
        align-items: center;

        & > button {
          background: none;
          border-radius: 2px;
          border: 1px solid #bdbdbd;
          display: flex;
          width: 30px;
          height: 30px;
          padding: 3px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }

  .footer-buttons {
    display: flex;

    .save {
      border-radius: 8px;
      background: #062b6e;
      width: 107px;
      height: 48px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      font-family: "Poppins";
      margin-top: 24px;
    }

    .reset {
      border-radius: 6px;
      border: 1px solid #062b6e;
      color: #062b6e;
      font-weight: 500;
      line-height: normal;
      width: 107px;
      height: 48px;
      font-size: 16px;
      font-style: normal;
      font-family: "Poppins";
      margin-top: 24px;
      margin-left: 30px;
      cursor: pointer;
    }
  }
}
