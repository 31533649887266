.video-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 50px;

  .video-item2 {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 20px;
    width: calc(25% - 20px); // Adjust as needed
    max-width: 300px; // Set maximum width
    height: auto;
    text-align: center;
    background-color: #f9f9f9;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #f6f8fc;

    .dummy-image2 {
      max-width: 100%;
      max-height: 150px; // Adjust the max-height as needed
      margin-bottom: 10px; // Add margin for spacing
      overflow: hidden; // Hide any overflow beyond max-height
      display: flex;
      justify-content: center;
      // align-items: center; // Center vertically

      &>img {
        width: auto;
        height: auto;
        max-width: 40%;
        max-height: 100%;
        object-fit: contain; // Fit the entire image within the container
      }
    }

    .text {
      text-align: left;
      color: #333;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-top: 16px;
    }

    &:hover {
      background-color: #e9e9e9;
    }

    &:nth-child(3n + 1) {
      margin-right: 0; // Remove margin for every third item to keep them aligned
    }
  }
}