.availibilty-edit {
  .back-arrow {
    .goto {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: inherit;
      display: flex;
      align-items: center;
      color: #212121;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 28px */
      text-decoration: none;
      .arrow-main {
        color: #4318ff;
        width: 24px;
        height: 24px;
      }
    }
  }
  .userName {
    color: #212121;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    margin-top: 30px;
  }
  .text2 {
    font-size: 18px;
  }
  .availability-status {
    display: flex;
    gap: 60px;
    margin-top: 36px;
    & > div:nth-child(1) {
      width: 250px;
    }
  }
  .submit-button {
    margin-top: 40px;
    & > button {
      border-radius: 6px;
      padding: 13px 30px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 6px;
      background: #062b6e;
    }
  }
}
