.candidate-details-container {
  font-family: Poppins;

  .details {
    background-color: #fff;
    margin-top: 100px;

    .title {
      color: #000;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 40px;
    }

    .content {
      font-family: "Poppins";
      border: 1px solid #e1e1e1;
      border-top: 4px solid #0b52d4;
      border-radius: 6px;
      background: #e5efff;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 98%;

      .info {
        .heading {
          margin-bottom: 20px;
          display: flex;

          h5 {
            color: var(--Black-High, #212121);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 120% */
            margin: 0px;
            padding: 0px;
            text-transform: capitalize;
          }

          .flag-user {
            margin-left: 20px;
            display: flex;
            align-items: center;
            gap: 4px;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 150% */

            // { marginLeft: "20px", display: "flex", alignItems: "center", color: "#D24D2C" }
          }
        }

        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          gap: 16px;
          color: #333;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;

          /* 150% */
          .icon {
            color: #0b52d4;
            background-color: inherit;
          }

          .text2 {
            color: #6a6868;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
          }
        }

        .flag-reason {
          .title {
            color: #d24d2c;
            text-align: center;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .reason {
            color: #333;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .round {
              font-weight: 500;
            }
          }
        }
      }

      .actions {
        margin-top: 20px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 36px;

        & > button {
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          border: none;
          outline: none;

          color: var(--White-High, #fff);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;

          /* font/button/large/sentence */
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 171.429% */
        }

        & > button:nth-child(1) {
          background: #062b6e;
        }
      }
    }
  }

  .candidate-details {
    margin-top: 30px;
    width: 98%;

    .title {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 25.2px */
    }

    .profile-card {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
      padding: 0px 30px;
      background-color: #fff;
      margin-top: 16px;

      .personal-details,
      .technical-skills {
        width: 45%;
        padding-top: 20px;

        h2 {
          margin: 0px;
          padding: 0px;
          color: #0b52d4;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 20px;
        }

        p {
          margin: 0px;
          padding: 0px;
          color: #333;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 22.4px */
          margin-bottom: 20px;

          span {
            color: #8b8b8b;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
            margin-right: 10px;
          }
        }
      }

      .personal-details {
        border-right: 1px dashed rgba(11, 82, 212, 0.5);
      }

      .technical-skills {
        width: 50%;

        .resume {
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #212121;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 5px;
          border: 1px solid #e0e0e0;
          background: #fff;
          box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
          width: fit-content;
          padding: 4px 10px;
          padding-bottom: 0px;
          background: #e5efff;
          cursor: pointer;

          .resume-icon {
            margin-right: 10px;
          }

          .resume-link {
            padding-top: 6px;
          }
        }
      }
    }
  }

  .report-card-container {
    margin-top: 62px;
    margin-bottom: 50px;
    width: 98%;

    .title {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 25.2px */
    }

    .report-card {
      border: 1px solid #ddd;
      border-radius: 5px;
      // padding: 20px;
      background-color: #fff;
      margin: auto;
      margin-top: 16px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      background: #fff;
      box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);

      & > div {
        border-bottom: 1px dashed rgba(11, 82, 212, 0.5);
      }
      & > div:last-child {
        border-bottom: none;
      }

      .round {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .round-details {
          flex: 1;

          h3 {
            margin: 0;
            padding: 0;
            color: #4f4f4f;
            font-family: "Poppins";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;

            & > span {
              color: #212121;
              font-family: "Poppins";
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
              /* 28px */
            }
          }

          .score {
            color: #4f4f4f;
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            // margin: 5px 0;
            margin: 0px;
            padding: 0px;
            margin-top: 16px;

            & > strong {
              color: #062b6e;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 140%;
              /* 33.6px */
            }
          }
        }

        .round-actions-column,
        .round-actions-row {
          display: flex;
          gap: 10px;
          flex-direction: column;

          .button {
            background-color: #fff;

            color: #0056b3;
            padding: 10px 15px;
            border-radius: 4px;
            border: 1px solid #062b6e;
            cursor: pointer;
            font-size: 0.9em;
            display: flex;
            align-items: center;
            text-align: center;
            gap: 4px;
            justify-content: center;
            color: #062b6e;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */

            &:hover {
              background-color: #0056b3;
              color: #fff;
            }
          }
        }

        .round-actions-row {
          gap: 24px;
          margin-top: 20px;
          flex-direction: row;
        }
      }
    }
  }
}
