.drop-down-container {
  color: #111;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  /* 22.4px */
  .drop-down-label {
    font-family: inherit;
    color: #000;
    /* Button Small */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
  }

  .select-value {
    margin-top: 8px;
    color: #111;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    border-radius: 4px;
    // border: 1px solid var(--Black-20, rgba(17, 17, 19, 0.2));
    outline: none;
    padding: 13px 16px;
    text-transform: capitalize;

    div {
      border: none;
      padding: 0px;
    }
  }

  .empty-value {
    font-weight: 400;
    color: #4f4f4f;
  }
}