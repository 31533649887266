.container {
  width: 100%;
  /* height: fit-content; */
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  /* align-items: center; */
  position: fixed;
  top: 0;
  inset: 0;
  /* overflow: hidden; */
  z-index: 15000;
}

.popup {
  /* width: 100%; */
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  background: #ffffff;
  min-height: 150px;
  height: fit-content;
  max-height: 95vh;
  overflow: auto;
  margin-top: 100px;
  border-radius: 8px;
  box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.05);
}
