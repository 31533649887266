.pagination-page-wrapper {
  text-align: center;
  margin: 100px auto;
  width: fit-content;

  .no-data-found {
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
