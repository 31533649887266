.back-arrow {
  .goto {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: inherit;
    display: flex;
    align-items: center;
    color: #212121;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    text-decoration: none;
    .arrow-main {
      color: #4318ff;
      width: 24px;
      height: 24px;
    }
  }
}
