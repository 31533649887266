.rubric-main {
  font-family: Poppins;
  font-style: normal;

  .main-title {
    color: #212121;
    font-size: 22px;
    font-weight: 600;
    line-height: 140%;
    margin-top: 50px;
    width: 100px;
    height: 31px;
  }

  .buttons {
    margin-top: 40px;
    width: 308px;

    .user {
      width: 154px;
      height: 48px;
      fill: #d2e1fd;
      stroke-width: 1px;
      border: 1px solid #e0e0e0;
      background: #fff;
      color: #8e8e8e;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      padding: 12px 0;
      box-sizing: border-box;
      cursor: pointer;
    }

    .hr {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    .expert {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .active {
      background-color: #d2e1fd;
      stroke: #0b52d4;
      color: #0b52d4;
      border: 1px solid #0b52d4;
      font-weight: 500;
    }
  }

  .rubric-container {
    margin-top: 50px;
    display: flex;
    gap: 50px;
    padding-right: 45px;

    .add-rubric-container {
      width: 25%;
      box-sizing: border-box;
      height: fit-content;
      border-radius: 6px;
      background: #fff;
      box-shadow: 2px 2px 2px 0px rgba(79, 79, 79, 0.11),
        -2px -2px 2px 0px rgba(79, 79, 79, 0.05);
      text-align: center;
      padding: 30px 28px;

      .add-rubric-icon {
        width: 44px;
        height: 42.949px;
        margin: 0 auto;
      }

      .rubric-title {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 19.6px */
        margin-top: 8px;
        margin-top: 8px;
      }

      & > div > button {
        border-radius: 8px;
        background: #062b6e;
        border: none;
        outline: none;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */
        padding: 9px 20px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}
