.round5Container {
  .wrapper {
    border-top: 1px dashed #bdbdbd;
    padding-bottom: 24px;
    &:nth-of-type(1) {
      margin-top: 24px;
      border: none;
      padding-bottom: 0px;
    }
    .row {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .flex {
        display: flex;
        gap: 5px;
      }
      .colorBlue {
        color: #062b6e;
      }

      .title {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 10px;
      }
      .title2 {
        font-size: 16px;
        font-weight: 500;
      }
      .comments {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .row2 {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .desc {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
      margin-top: 16px;
    }
  }
  .wrapper2 {
    &:nth-of-type(2),
    &:nth-of-type(3) {
      border: none;
      .row {
        margin-top: 0px !important;
      }
    }
  }
  .round5Wrapper {
    .wrapper {
      &:nth-of-type(1) {
        border-top: 1px dashed #062b6e;
      }
      &:nth-of-type(2) {
        border: none;
      }
    }
  }
}
