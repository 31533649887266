.assign-hr-main {
    font-family: Poppins;
    padding-top: 60px;

    .title {
        margin-top: 40px;
        color: #212121;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 30.8px */
    }

    .candidate-detail-card {
        margin-top: 24px;
        // width: 841px;
        width: 900px;
        height: 126px;
        border-radius: 6px;
        border: 1px solid #E1E1E1;
        background: #FFF;
        box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.08), 2px 2px 4px 0px rgba(79, 79, 79, 0.08);
        padding: 20px 4px;
    }

    .detail-header {
        height: 22px;
        color: #212121;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        padding-left: 16px;
        /* 22.4px */
    }

    .detail-body {
        margin-top: 20px;
        display: flex;
        color: #616161;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */

        .detail-info {
            padding: 0px 16px;
            border-right: 1px solid #E0E0E0;

            &>div:nth-child(2) {
                margin-top: 6px;
                font-size: 11px;
                font-weight: 500;
            }
        }

        &>div:last-child {
            border: none;
        }
    }

    .search-and-filter {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 98%;


        .search {
            width: 455px;
            height: 44px;
        }

        .filterButton {
            border-radius: 6px;
            background: #4318ff;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 7px 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            outline: none;
            border: none;
            gap: 10px;
            max-height: 44px;
        }
    }

    .table-container {
        width: 98%;
        margin-top: 50px;
        overflow-x: auto; // Allows horizontal scrolling
        box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.1);

        .hr-table {
            min-width: 100%; // Minimum width of the table
            border-collapse: collapse; // Removes space between borders
            background: #f3f0ff;

            thead {
                background: #f3f0ff;

                tr {

                    // padding: 20px;
                    th {
                        text-align: left;
                        white-space: nowrap; // Keeps the header text on a single line
                        color: #616161;
                        font-family: "Poppins";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        padding: 20px;
                        /* 142.857% */
                    }

                    th:nth-child(1) {
                        padding: 20px;
                    }

                    th:nth-last-child(1) {
                        padding: 20px;
                    }
                }
            }

            tbody {
                tr {
                    position: relative;

                    &:nth-child(odd) {
                        background-color: #f6f6f6;
                    }

                    td {
                        color: #616161;
                        font-family: "Poppins";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        /* 142.857% */
                        padding: 20px;
                    }

                    td:nth-child(1) {
                        padding: 20px;
                        // padding-right: 0px;
                    }

                    td:nth-last-child(1) {
                        padding: 20px;
                        // padding-left: 0px;
                    }

                    .invite {
                        width: 112px;
                        height: 36px;
                        border-radius: 4px;
                        border: 1.2px solid #062B6E;
                        color: #062B6E;
                        text-align: center;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        background-color: #fff;
                        cursor: pointer;
                        /* 150% */
                    }
                }
            }
        }
    }

    .page-wrapper {
        margin: 56px auto;
    }

    .meeting-invite-main {
        padding: 30px;
        width: 620px;
        height: 587px;

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close-button {
                cursor: pointer;
            }
        }

        .modal-header div {
            color: #212121;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .modal-body {
            margin-top: 40px;

            .date-time {
                display: flex;
                gap: 30px;
                font-size: 16px;
                font-style: normal;
                color: #212121;
                font-weight: 500;
                line-height: 140%;

                .scheduled-date {
                    display: flex;
                    flex-direction: column;
                    /* 22.4px */
                }

                .scheduled-date label {
                    width: 130px;
                    height: 22px;

                }

                .scheduled-date input {
                    margin-top: 5px;
                    width: 178px;
                    height: 48px;
                    padding: 14px 16px;
                    color: #4F4F4F;
                    border-radius: 8px;
                    border: 1px solid #E0E0E0;
                    background: #F8F9FB;
                }

                .scheduled-time input {
                    margin-top: 5px;
                    display: flex;
                    width: 150px;
                    height: 48px;
                    padding: 14px 16px;
                    align-items: center;
                    border-radius: 8px;
                    border: 1px solid #E0E0E0;
                    background: #F8F9FB;
                    color: #4F4F4F;
                }

                .time-slot {
                    display: flex;
                    align-items: center;
                }

                .time-slot input {
                    width: auto;
                }

                .time-slot span {
                    margin: 0 8px;
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .attendees-main {
                margin-top: 30px;
                display: flex;
                gap: 200px;

                .attendees {
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    border-radius: 8px;
                    border: 1px solid #E0E0E0;
                    background: #F8F9FB;
                    padding: 15px 18px 14px 16px;
                    width: 178px;
                    height: 48px;
                    position: relative;

                    .attendees-search-list {
                        position: absolute;
                        width: 178px;
                        max-height: 208px;
                        border-radius: 8px;
                        background: #FFF;
                        box-shadow: -2px -2px 4px 0px rgba(33, 33, 33, 0.12), 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
                        padding: 12px 16px;
                        top: 50px;
                        left: 0px;
                        font-style: normal;
                        line-height: normal;
                        overflow: hidden;
                        overflow-y: scroll;
                        scrollbar-width: thin;

                        .attendee-info {
                            margin-bottom: 16px;
                            cursor: pointer;

                            .attendee-name {
                                color: rgba(79, 79, 79, 0.90);
                                font-size: 14px;
                                font-weight: 500;
                            }

                            .attendee-email {
                                color: #888;
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }
                    }
                }

                .attendees input {
                    width: 108px;
                    margin-right: 18px;
                    border: none;
                    background-color: transparent;
                    color: rgba(79, 79, 79, 0.54);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .attendees input:focus {
                    outline: none;
                }

                .timezone {
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;

                    .timezone-label {
                        color: #212121;
                        line-height: 140%;
                        /* 22.4px */
                    }

                    .timezone-select-value {
                        cursor: pointer;
                        font-family: "Poppins";
                        margin-top: 8px;
                        height: 48px;
                        padding: 14px 16px;
                        align-items: center;
                        border-radius: 8px;
                        border: 1px solid #E0E0E0;
                        background: #F8F9FB;
                        width: 178px;
                        color: #4F4F4F;
                        line-height: normal;
                    }
                }

            }

            .attendees-main label {
                color: #212121;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                /* 22.4px */
            }


            .existing-attendees {
                display: flex;
                gap: 6px;
                margin-top: 5px;
                height: 28px;

                .attendee {
                    color: #949494;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 6px;
                    background: #E6EFFF;
                    padding: 6px 10px;
                    display: flex;
                    gap: 18px;
                    align-items: center;

                    &>img {
                        height: 20px;
                        width: 20px;
                        cursor: pointer;
                    }
                }
            }

            .meeting-note {
                margin-top: 17px;
            }

            .meeting-note label {
                color: #212121;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                /* 22.4px */
            }

            .meeting-note textarea {
                font-family: "Poppins";
                width: 560px;
                height: 117px;
                margin-top: 8px;
                padding: 12px;
                // color: rgba(119, 119, 119, 0.47);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                border-radius: 4px;
                border: 1px solid #CFCDCD;
                background: #FFF;
                /* 18.2px */
            }
        }

        .modal-footer {
            margin-top: 40px;

            .cancel-button,
            .send-button {
                padding: 12px 20px;
                border: none;
                cursor: pointer;
                border-radius: 8px;
                width: 97px;
                height: 48px;
                font-size: 16px;
                font-style: normal;
                line-height: normal;
            }

            .cancel-button {
                border: 1px solid #777;
                color: #777;
                font-weight: 400;
            }

            .send-button {
                background: #062B6E;
                margin-left: 30px;
                color: #FFF;
                font-weight: 500;
            }
        }

    }
}