.interview-container {
  font-family: Poppins;
  font-style: normal;

  .title {
    color: #212121;
    font-size: 22px;
    font-weight: 600;
    line-height: 140%;
    margin-top: 50px;
    width: 100px;
    height: 31px;
  }

  .assign-buttons {
    margin-top: 40px;
    width: 308px;

    .assign {
      width: 154px;
      height: 48px;
      fill: #d2e1fd;
      stroke-width: 1px;
      border: 1px solid #e0e0e0;
      background: #fff;
      color: #8e8e8e;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      padding: 12px 0;
      box-sizing: border-box;
      cursor: pointer;
    }

    .hr {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    .expert {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .active {
      background-color: #d2e1fd;
      stroke: #0b52d4;
      color: #0b52d4;
      border: 1px solid #0b52d4;
      font-weight: 500;
    }
  }

  .search-and-filter {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;

    .search {
      width: 455px;
      height: 44px;
    }

    .filterButton {
      border-radius: 6px;
      background: #4318ff;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 7px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      gap: 10px;
      max-height: 44px;
    }
  }

  .interview-request-buttons {
    margin-top: 36px;
    display: flex;
    gap: 60px;
  }

  .interview-request-buttons button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #8e8e8e;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .interview-request-buttons button.active {
    color: #0b52d4;
    border-bottom: 2px solid #0b52d4;
  }

  .table-container {
    width: 98%;
    margin-top: 35px;
    overflow-x: auto; // Allows horizontal scrolling
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.1);

    .user-table {
      min-width: 100%; // Minimum width of the table
      border-collapse: collapse; // Removes space between borders
      background: #f3f0ff;

      thead {
        background: #f3f0ff;

        tr {
          // padding: 20px;
          th {
            text-align: left;
            white-space: nowrap; // Keeps the header text on a single line
            color: #616161;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding: 20px;
            /* 142.857% */
          }

          th:nth-child(1) {
            padding: 20px;
          }

          th:nth-last-child(1) {
            padding: 20px;
          }
        }
      }

      tbody {
        tr {
          position: relative;

          &:nth-child(odd) {
            background-color: #f6f6f6;
          }

          td {
            color: #616161;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            padding: 20px;

            .flag {
              height: 16px;
              width: 16px;
            }
          }

          td:nth-child(1) {
            padding: 20px;
            // padding-right: 0px;
          }

          td:nth-last-child(1) {
            padding: 20px;
            // padding-left: 0px;
          }

          .assign-hr {
            width: 150px;
            height: 36px;
            border-radius: 4px;
            border: 1.2px solid #062b6e;
            color: #062b6e;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            background-color: #fff;
            cursor: pointer;
            /* 150% */
          }

          .expired {
            border: 1.2px solid #a8bee6;
            color: #a8bee6;
          }
        }
      }
    }
  }

  .page-wrapper {
    margin: 56px auto;
  }

  .interview-filter {
    width: 100vw;
    height: 100vh;
    position: absolute;
    inset: 0;
    .popupMainWrapper {
      font-family: "Poppins";
      padding: 20px 30px;
      position: relative;
      width: 300px;
      float: right;
      margin-right: 40px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 2px 2px 2px 0px rgba(79, 79, 79, 0.24),
        2px 2px 2px 0px rgba(79, 79, 79, 0.16);

      .filter-title {
        display: flex;
        justify-content: space-between;

        & > span {
          margin-top: 40px;
          color: #212121;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
        }

        & > div {
          cursor: pointer;
        }
      }
      .date {
        margin-top: 40px;
        & > div {
          color: #4f4f4f;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        & > input {
          margin-top: 20px;
          border-radius: 4px;
          background: rgba(204, 222, 255, 0.46);
          width: 234px;
          height: 36px;
          padding: 8px;
          border: none;
        }
      }
      .location, .status, .flag {
        margin-top: 40px;
        & > div:first-child {
          color: #4f4f4f;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
        .drop-down-container > div {
          border: none;
          border-radius: 4px;
          background: rgba(204, 222, 255, 0.46);
          width: 234px;
          height: 36px;
          padding: 8px;
        }
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 32px;
        & > div {
          width: fit-content;
          cursor: pointer;
          padding: 12px;
        }
        & > div:nth-child(1) {
          color: #9c9ba4;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
        & > div:nth-child(2) {
          color: #4318ff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
        }
      }
    }
  }
}
