.question-container {
  .question-title {
    width: 109px;
    height: 31px;
    margin-top: 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #212121;
  }

  .info {
    width: 543px;
    height: 23px;
    margin-top: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: normal;
    color: #7c7c7c;
  }

  .dropdown-container {
    display: flex;
    margin-top: 30px;

    .field-container {
      width: 240px;
      height: 78px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
    }

    .round-container {
      width: 240px;
      height: 78px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
      margin-left: 40px;
    }

    .questions-on-container {
      width: 240px;
      height: 78px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
      margin-left: 40px;
    }

    .level-container {
      width: 240px;
      height: 78px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      /* 22.4px */
      margin-left: 40px;
    }

    .drop-down-container [MenuItem] {
      background-color: gray;
    }
  }

  .question-files {
    display: flex;
    margin-top: 40px;

    .file-main {
      .dropBox {
        padding: 41px 49px 42px 48px;
        justify-content: center;
        flex-shrink: 0;
        width: 449px;
        height: 171px;
        border-radius: 8px;
        border: 1px dashed #e0e0e0;
        background: #fff;
        box-shadow: 2px 2px 4px 0px rgba(79, 79, 79, 0.03);
        display: flex;
        flex-direction: column;
        align-items: center;

        .uploadIcon {
          & > img {
            width: 24px;
            height: 24px;
          }
        }

        .firstLabel {
          color: #4f4f4f;
          width: 352px;
          height: 23px;
          font-family: "Poppins";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 16px;

          & > label {
            & > span {
              color: #062b6e;
              font-family: "Poppins";
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .secondLabel {
          color: #afafaf;
          font-family: "Poppins";
          font-style: normal;
          margin-top: 8px;
          font-size: 11px;
          font-weight: 400;
          line-height: normal;
        }
      }

      .selected {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        height: fit-content;
        align-items: center;
        background: #fff;
        width: fit-content;
        margin-top: 14px;
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        justify-content: flex-start;

        & > div {
          padding: 13px 12px;

          & > img {
            cursor: pointer;
          }
        }
      }
    }

    .download-sample-file {
      margin-top: 62px;
      margin-left: 80px;

      & > input {
        cursor: pointer;
        width: 217px;
        color: #062b6e;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #062b6e;
        padding: 12px 20px;
        background-color: white;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .question-submit {
    width: 97px;
    height: 48px;
    border-radius: 8px;
    background: #062b6e;
    cursor: pointer;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .disabled-button {
    cursor: not-allowed !important;
  }
}
