.back-button {
    top: 100px;
    display: flex;
    cursor: pointer;
    position: absolute;

    &>span {
        color: #212121;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 28px */
    }
}