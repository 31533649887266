.dialog-main {
  position: relative;
  padding: 20px;
  text-align: center;
  min-width: 387px;
  max-width: 650px;

  .dialog-close {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
  }

  .dialog-title1 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    // color: #34a853;
  }

  .dialog-title2 {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 4px;
  }

  .action-buttons {
    display: flex;
    gap: 36px;
    justify-content: center;

    .dialog-okay {
      width: 90px;
      height: 44px;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
      // background: #4318ff;
      background: #062b6e;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      margin-top: 20px;
    }

    .dialog-cancel {
      width: 90px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #777;
      color: #777;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
